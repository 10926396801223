@import "./index.scss";

.programas {
  padding: 2rem 0;
  h1 {
    border-bottom: 1px solid $light-blue;
    color: $dark-blue;
    font-size: 2rem;
    padding-bottom: 1rem;
    width: 100%;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    justify-content: space-between;
    li {
      width: 45%;
      height: auto;
      margin-bottom: 2rem;
      img {
        width: 100%;
      }
      a,
      a:visited {
        transition: transform 1s;
      }
      a:hover,
      a:active {
        img {
          transform: scale(1.1);
        }
      }
      @include md {
        width: 22%;
      }
    }
  }
}
