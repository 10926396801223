@import "./index.scss";

.main-header {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;

  .main-menu {
    width: 100%;
    .container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    img {
      max-width: 140px;
      @include md {
        max-width: 180px;
      }
    }
    .buttons {
      display: flex;
    }
    button {
      align-items: center;
      background-image: linear-gradient(45deg, $main-blue, #014dd5);
      color: #fff;
      cursor: pointer;
      display: flex;
      filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.05));

      margin-left: 1rem;
      padding: 1rem;
      span {
        display: none;
        @include md {
          display: initial;
        }
      }
      svg {
        font-size: 1.2rem;
        margin-right: 0;
        @include md {
          margin-right: 0.8rem;
        }
      }
    }
    button:hover,
    button:active {
      background-image: linear-gradient(45deg, $main-blue, $main-blue);
    }
  }
}
