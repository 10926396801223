@import "./index.scss";

.main-footer {
  .social-links {
    background-color: #f3f3f3;
    ul {
      align-items: center;
      display: flex;
      flex-direction: column;
      @include md {
        flex-direction: row;
        margin-bottom: 0;
      }
      li {
        background-color: $main-blue;
        filter: drop-shadow(4px 4px 5px rgba(0, 0, 0, 0.05));
        padding: 1rem 2rem;
        margin-top: 1rem;
        @include md {
          margin: 0 1rem;
        }
      }
    }
    a,
    a:visited {
      align-items: center;
      color: #fff;
      display: flex;
    }
    a:hover,
    a:active {
      text-decoration: none;
    }
    svg {
      color: $light-blue;
      font-size: 2rem;
      margin-right: 1rem;
    }
  }
  background-color: $dark-blue;
  color: #fff;
  a,
  a:visited {
    color: #fff;
  }
  a:hover,
  a:active {
    text-decoration: underline;
  }
  address {
    margin-top: 2rem;
    p {
      color: #fff;
      font-size: 0.9rem;
      font-style: normal;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include md {
      flex-direction: row;
    }
  }
  .column {
    @include md {
      width: 33%;
    }
  }
  .links {
    display: flex;
    justify-content: space-evenly;
    @include md {
      width: 40%;
    }
  }
  .about,
  .logos {
    text-align: center;
    @include md {
      width: 24%;
    }
  }
  .about {
    margin-bottom: 3rem;
    @include md {
      margin-bottom: 0;
    }
  }
  .logos {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    img {
      max-width: 240px;
    }
    @include md {
      margin-top: 0;
    }
    span {
      max-width: 240px;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      h4 {
        font-size: 0.8rem;
        text-transform: uppercase;
      }
      .column {
        width: 45%;
        img {
          margin-top: 1rem;
        }
      }
    }
  }
  h2 {
    font-size: 1.4rem;
  }
  img {
    height: auto;
    max-width: 320px;
    width: 100%;
  }
  ul {
    margin-top: 0.8rem;
    margin-bottom: 1.4rem;
  }
}
